import axios from 'axios';
import React, {Component} from 'react';
import {withRouter} from 'react-router';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import TextTruncate from 'react-text-truncate';

import {AddToCartButton} from 'components/AddToCartButton';
import {Button} from '@partssourceinc/react-ui-core';
import {ProductImage} from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import {QuantitySelector} from '@partssourceinc/react-ui-core';
import RequestQuote from 'components/RequestQuote';
import * as UserStore from 'stores/User';
import {formatMoney, getCookie, logEvent} from 'utility';
import {getPartCondition} from 'productUtility';
import 'less/lists.less';

@withRouter
@connect(state => ({user: state.user}))
export default class BuyItAgain extends Component {
    static propTypes = {
        user: UserStore.StateShape,
    };
    
    constructor(props) {
        super(props);
        const {user: {facility}, user} = props;

        const facilityId = facility.facilityId > 0 ? facility.facilityId : user.facilities[0].facilityId;

        this.state = {
            buyAgainList: [],
            showRequestQuote: false,
            start: 0,
            productList: [],
            productGridValues: {
                pageSize: 10,
                activePage: 1,
                total: 0,
                quantity: 0,
            },
            loading: false,
            facilityId,
        };
    }

    componentDidMount() {
        const {facilityId} = this.state;
        this.getBuyAgainProducts(facilityId);
    }

    componentWillReceiveProps(newProps) {
        const {facilityId} = this.state;

        if (newProps.user.facility.facilityId && newProps.user.facility.facilityId !== facilityId) {
            this.refreshState(newProps);
        }
    }

    refreshState(props) {
        const {user: {facility}, user} = props;
        const facilityId = facility.facilityId > 0 ? facility.facilityId : user.facilities[0].facilityId;

        this.setState({
            facilityId,
        });

        this.getBuyAgainProducts(facilityId)
    }

    getBuyAgainProducts(facilityId) {
        const {start} = this.state;
        this.setState({loading: true});
        axios
            .get(`/CatalogService/api/v1/buy-again?start=${start}&facilityId=${facilityId}`)
            .then(resp => this.setState({productList: resp.data, loading: false}));
    }

    showRequestQuote() {
        this.setState({showRequestQuote: true});
    }

    handlePaging(newPage) {
        const {facilityId} = this.state;
        this.setState({productGridValues: {activePage: newPage}});
        this.getBuyAgainProducts(facilityId);
    }

    handleClick(option) {
        if (option)
            logEvent('BUY IT AGAIN CLICK', {'V-string': option && option.vendorItemNumber ? option.vendorItemNumber : ''});
    }

    renderProduct(detail, allOptions, hasFacilityLevelPricing, facility, hidePricing) {
        const {history} = this.props;
        const {product, productId, option} = detail;
        const facilitySet = hasFacilityLevelPricing && (facility && facility.facilityId === -1);
        
        return (
            <div
                className="list-item"
                style={{width: '245px'}}
                key={`pdiv_${productId}`}
            >
                <div className="pList-image">
                    {option || product.thumbnailUrl ? (
                        <ProductImage url={option && option.images && option.images.length > 0 ? option.images[0].thumbnail : (product.thumbnailUrl ?? '')} />
                    ) : (
                        <ProductImage />
                    )}
                </div>
                <div className="pList-title">
                    <NavLink to={product.isVariantProduct && option ? `${product.detailUrl}?vrnt=${option.vendorItemNumber}` : product.detailUrl} onClick={() => this.handleClick(option)} title={option ? option.description : product.description} style={{color: '#005da6', textDecoration: 'none'}}>
                        <TextTruncate line={4} truncateText="…" text={option ? option.description : product.description} textTruncateChild="" />
                    </NavLink>
                </div>
                {facilitySet ? (
                    <p style={{'margin-top': '7px'}}>Choose Facility to See Pricing</p>
                ) : option ? (
                    <div>
                        {!hidePricing && <div className="pList-price">{formatMoney(option.price)}</div>}
                        <div className="pList-condition">
                            {option.purchaseChoice === 2 && <img src="/images/exchange.svg" alt="Exchange Item" style={{height: '15px', padding: '0 2px 0 0'}} />}  
                            {getPartCondition(option.lineItemCondition)}
                            <span>
                                {option.purchaseChoice === 1
                                    ? ' Outright Purchase'
                                    : option.purchaseChoice === 2
                                        ? ' Exchange Purchase'
                                        : ' Repair Purchase'}
                            </span>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {option ? (
                    <div className="pList-buttons">
                        <AddToCartButton product={product} option={option} />
                    </div>
                ) : allOptions && allOptions.length > 0 ? (
                    <p>
                        <a href={product.detailUrl}>More Buying Choices</a>
                    </p>
                ) : (
                    <div className="pList-buttons">
                        <Button data-ea-cta-link="CTA Link Clicked"
                            secondary={true}
                            title="request quote"
                            style={{width: '135px'}}
                            onClick={() => history.push('/quote/request')}
                        >
                            REQUEST QUOTE
                        </Button>
                    </div>
                )}
            </div>
        );
    }

    render() {
        const {
            user: {
                facility,
                settings: {hasFacilityLevelPricing, hidePricing},
            },
        } = this.props;

        const {
            productList,
            productList: {allOptions},
            loading,
            showRequestQuote,
        } = this.state;

        return (<React.Fragment>
            <PageMetaData title="Buy It Again" />
            <div className="list-page">
                {loading && <div className="loading-data">
                    <div className="loader" />
                </div>}
                <h1>Buy it Again</h1>
                {!loading && <div>
                    <div>
                        {productList.purchases && productList.purchases.length > 0
                            ? productList.purchases.map((prod, index) =>
                                this.renderProduct(prod, allOptions[index], hasFacilityLevelPricing, facility, hidePricing))
                            : 'No ordered products'}
                    </div>
                </div>}
                {showRequestQuote && <RequestQuote
                    onClose={() => this.setState({showRequestQuote: false})}
                    onCancel={() => this.setState({showRequestQuote: false})}
                />}
            </div>
        </React.Fragment>);
    }
}
